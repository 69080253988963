@import '~antd/dist/antd.less';

html {
  font-size: 16px;

  @media screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 15px;
  }

  @media screen and (min-width: 350px) and (max-width: 600px) {
    font-size: 14px;
  }

  @media screen and (max-width: 350px) {
    font-size: 12px;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

body {
  font-family: 'Lato';
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-BlackItalic.eot');
  src: url('./assets/fonts/Lato/Lato-BlackItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-BlackItalic.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-BlackItalic.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-BlackItalic.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-BlackItalic.svg#Lato-BlackItalic')
      format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Bold.eot');
  src: url('./assets/fonts/Lato/Lato-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Bold.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Bold.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-Bold.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-Bold.svg#Lato-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Black.eot');
  src: url('./assets/fonts/Lato/Lato-Black.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Black.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Black.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-Black.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-Black.svg#Lato-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-BoldItalic.eot');
  src: url('./assets/fonts/Lato/Lato-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-BoldItalic.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-BoldItalic.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-BoldItalic.svg#Lato-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Italic.eot');
  src: url('./assets/fonts/Lato/Lato-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Italic.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Italic.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-Italic.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-Italic.svg#Lato-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-LightItalic.eot');
  src: url('./assets/fonts/Lato/Lato-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-LightItalic.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-LightItalic.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-LightItalic.svg#Lato-LightItalic')
      format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Light.eot');
  src: url('./assets/fonts/Lato/Lato-Light.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Light.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Light.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-Light.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-Light.svg#Lato-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Regular.eot');
  src: url('./assets/fonts/Lato/Lato-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Regular.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Regular.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-Regular.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-Regular.svg#Lato-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Hairline.eot');
  src: url('./assets/fonts/Lato/Lato-Hairline.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Hairline.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Hairline.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-Hairline.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-Hairline.svg#Lato-Hairline') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-HairlineItalic.eot');
  src: url('./assets/fonts/Lato/Lato-HairlineItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-HairlineItalic.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-HairlineItalic.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-HairlineItalic.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-HairlineItalic.svg#Lato-HairlineItalic')
      format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

